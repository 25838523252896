@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --scroll-snap-stop: always;
    --scroll-snap-align: start;
    --perspective: 30dvh;
    --scroll-behavior: smooth;
    --font-ubuntu: "Ubuntu", sans-serif;
    --font-inter: "Inter", sans-serif;
    --font-mono: "Mono", sans-serif;
    --header-height: 60px;

    /* CUSTOMIZED COLORS BELOW */
    --gradient: #002147;
    --background: 225 100% 98.28%;
    --foreground: 220 100% 10%;

    --muted: 225 10% 91.4%;
    --muted-foreground: 225 5% 61.4%;
    --popover: 225 10% 91.4%;
    --popover-foreground: 225 10% 0.7000000000000001%;
    --card: 225 10% 91.4%;
    --card-foreground: 225 10% 0.7000000000000001%;

    --border: 225 15% 89.56%;
    --input: 225 15% 89.56%;

    --primary: 225 100% 14%;
    --primary-foreground: 225 2% 91.4%;

    --secondary: 225 5% 95.7%;
    --secondary-foreground: 225 7% 11.4%;

    --accent: 225 5% 95.7%;
    --accent-foreground: 225 7% 11.4%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --ring: 225 100% 14%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 10%;
    --foreground: 0 0% 90%;
    --card: 0 0% 15%;
    --card-foreground: 0 0% 90%;
    --popover: 0 0% 20%;
    --popover-foreground: 0 0% 90%;
    --primary: 220 50% 60%;
    --primary-foreground: 0 0% 10%;
    --secondary: 45 30% 60%;
    --secondary-foreground: 0 0% 90%;
    --muted: 0 0% 20%;
    --muted-foreground: 0 0% 70%;
    --accent: 300 50% 60%;
    --accent-foreground: 0 0% 10%;
    --destructive: 0 70% 60%;
    --destructive-foreground: 0 0% 10%;
    --border: 0 0% 30%;
    --input: 0 0% 30%;
    --ring: 220 50% 60%;
  }
}

@layer components {
  .rotate-y-180 {
    transform: rotateY(180deg);
  }

  .postal-card {
    transform: skew(-1.2deg, 1.2deg) rotate(0.44deg);
    box-shadow: rgba(0, 0, 0, 0.2) -10px 10px 20px;
  }

  .-postal-card {
    transform: skew(1.2deg, -1.2deg) rotate(-0.44deg);
    box-shadow: rgba(0, 0, 0, 0.2) -10px 10px 20px;
  }
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .text-gradient {
    @apply text-transparent bg-clip-text;
  }
}

html,
body {
  overflow-x: hidden;
}

.calendly-popup {
  max-height: calc(
    100dvh - var(--header-height) - var(--header-height)
  ) !important;
  height: 100% !important;
}

div[data-container="booking-container"] {
  max-width: inherit !important;
  max-height: inherit !important;
}

body {
  width: 100dvw;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

.citation {
  position: relative;
  display: inline-block;
  padding: 4px;
  border-radius: 0.5rem;
  color: var(--foreground);
  font-style: italic;
  --gap: -16px;
}

.citation::before {
  content: "“";
  font-size: 3rem;
  position: absolute;
  top: -12px;
  left: -12px;
  line-height: 1;
  color: hsl(var(--primary));
}

.citation::after {
  content: "”";
  font-size: 3rem;
  position: absolute;
  bottom: -16px;
  right: -4px;
  line-height: 1;
  color: hsl(var(--primary));
}

.citation strong {
  font-weight: 700;
  background: hsl(var(--primary) / 0.5);
  display: inline;
  padding: 2px;
  margin: -2px;
}

.dot-pattern {
  background-color: var(--dot-background);
  opacity: 0.8;
  background-image: radial-gradient(
    var(--dot-color) 0.5px,
    var(--dot-background) 0.5px
  );
  background-size: var(--size, 10px) var(--size, 10px);
}

.prose {
  scroll-margin-top: 40px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    position: relative;
    scroll-margin-top: 70px;

    &:hover {
      & .icon-link {
        opacity: 1;
      }
    }
  }

  & .icon.icon-link {
    width: 20px;
    height: 100%;
    display: block;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -24px;

    opacity: 0;
    transition: opacity 0.2s ease;

    &::before {
      content: "#";
      font-size: 1.5rem;
      color: hsl(var(--primary));
    }
  }
}

body {
  scroll-behavior: smooth;
}

section {
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  perspective: 500px;
}

header {
  scroll-snap-align: center;
}

h1,
h2 {
  scroll-snap-align: center;
}

footer {
  scroll-snap-align: end;
}

.bg-background-logo {
  opacity: 0.07 !important;
}

.bg-foreground-logo {
  opacity: 0.2 !important;
}

.scroll-container {
  scroll-behavior: var(--scroll-behavior);
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.scroll-container > * {
  scroll-snap-align: var(--scroll-snap-align);
  scroll-snap-stop: var(--scroll-snap-stop);
}
